import { getProductNameReq } from "@/network/reqApi/common.js";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Router from "@/router/index";

export function getCapLetters() {
  let str = [];
  for (var i = 65; i < 91; i++) {
    str.push(String.fromCharCode(i));
  }
  return str;
}

// 获取 sjtName, sstName 的名称
export async function getSjtName() {
  let sjtName = "";
  // 同步操作
  await getProductNameReq().then((res) => {
    if (res.code == 200) {
      sjtName = res.data.filter((el) => {
        return el.id == 21;
      })[0].name;
    }
  });
  return sjtName;
}

export async function getSstName() {
  let sstName = "";
  // 同步操作
  await getProductNameReq().then((res) => {
    if (res.code == 200) {
      sstName = res.data.filter((el) => {
        return el.id == 22;
      })[0].name;
    }
  });
  return sstName;
}

// 获取当前项目的key
// 注，此处需要使用 sessionStorage
// 若使用 localStorage 会导致，登录不同项目，后进入的项目给之前进入的项目 key 给覆盖了
// 使用 session ,一次网页的请求对应一个 session ,不同网页之间不会相互影响
const KEY = "key";
export function saveKey(valueOfKey) {
  if (sessionStorage.getItem(KEY)) {
    sessionStorage.removeItem(KEY);
  }
  sessionStorage.setItem(KEY, valueOfKey);
}

export function getKey() {
  return sessionStorage.getItem(KEY);
}

// 配置用户当前指纹
export function configUserFingerPrint() {
  // 验证指纹
  let getFingerPrint = async () => {
    const fp = await FingerprintJS.load({ screen_resolution: true });
    const result = await fp.get();
    const visitorId = result.visitorId;
    if (localStorage.getItem("finger")) {
      localStorage.removeItem("finger");
    }
    localStorage.setItem("finger", visitorId);
  };
  getFingerPrint();
}

// 保存上次登陆时候的项目状态，决定当用户超时后，是进入那个页面
// 如果是提前录入模式，则进入提示用户重新点击链接进入
// 如果是工号模式，则直接进入登录页
const lastProjectModeKey = "lastProjectModeKey";
export function savelastProjectModeKey(valueOfKey) {
  if (localStorage.getItem(lastProjectModeKey)) {
    localStorage.removeItem(lastProjectModeKey);
  }
  localStorage.setItem(lastProjectModeKey, valueOfKey);
}
export function getlastProjectModeKey() {
  return localStorage.getItem(lastProjectModeKey);
}

// 记录一下当前路由
// 使用场景1：当用户本地token失效后，读取当前路由，判断用户身份是管理员，还是答题考生，将用户导到不同的页面
const currentRoute = "currentRoute";
export function saveCurrentRouteKey(valueOfKey) {
  if (localStorage.getItem(currentRoute)) {
    localStorage.removeItem(currentRoute);
  }
  localStorage.setItem(currentRoute, valueOfKey);
}
export function getCurrentRouteKey() {
  return localStorage.getItem(currentRoute);
}

/**
 * @description: 根据当前路由，用户上次登陆项目类型，决定用户应该进入那个路由
 * 如果是管理员，要将页面更新至管理员登录页面
 * 如果是考生
 * 若上次登陆的是开发模式，则导入到账号登录页面
 * 若上次登陆的是提前录入模式，则导入到通过链接登录页面
 * @return {*}
 */
export function reLoginWhenRemoveLocalToken() {
  let currentPath = getCurrentRouteKey();
  let lastProjectModeKey = getlastProjectModeKey();
  let isAdmin = false;

  // 如果是管理员，要将页面更新至管理员登录页面
  isAdmin = isAdminRoute(currentPath);
  if (isAdmin) {
    // 访问管理员首页
    location.href = "/admin/login";
    return;
  }

  //  * 如果是考生
  //  * 若上次登陆的是开放模式【0】，则导入到账号登录页面
  //  * 开发模式，必须要携带项目key作为参数，否则会报项目不存在
  //  * 若上次登陆的是提前录入模式【1】，则导入到通过链接登录页面
  switch (String(lastProjectModeKey)) {
    case "0": {
      let key = getKey();
      if (key) {
        Router.push({
          name: "userLogin",
          query: {
            key: key,
          },
        });
      } else {
        Router.push({
          name: "lastLoginTips",
        });
      }
      break;
    }
    case "1": {
      Router.push({
        name: "lastLoginTips",
      })
      break;
    }
    default: {
      break;
    }
  }
}

/**
 * @description: 判断当前路由是否是管理员路由
 * 若路由包括 /manage，或者admin 字样，则认为是管理员路由
 * @param {*} path
 * @return {*}
 */
function isAdminRoute(path) {
  let isAdmin = false;
  let tarStrArray = ["/manage", "admin"];
  tarStrArray.forEach((el) => {
    let pathStr = String(path);
    if (pathStr.indexOf(el) > -1) {
      isAdmin = true;
    }
  });
  return isAdmin;
}
